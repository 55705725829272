import React from 'react';
import ContactForm from './ContactForm';
import config from '../../config';
export default function Footer() {
  return (
    <div id="footer">
      <div className="container medium">
        <header className="major last">
          <h2>Get in touch!</h2>
        </header>

        <p>Need help with my music? I'll be happy to assist you!</p>

        <ContactForm />

        <h2>Stay tuned!</h2>
        <ul className="icons">
          {config.socialLinks.map(social => {
            const { icon, name, url } = social;
            return (
              <li key={url}>
                <a
                  href={url}
                  className={`icon ${icon}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  title={`Blake Wolf on ${name}`}
                >
                  <span className="label">{name}</span>
                </a>
              </li>
            );
          })}
        </ul>

        <ul className="copyright">
          <li>Blake Wolf &copy; {new Date().getFullYear()}</li>
        </ul>
      </div>
    </div>
  );
}
