import React from 'react';
// import config from '../../config';
import Image from './Image';

export default function Header() {
  return (
    <div id="header">
      <Image alt="Blake Wolf" filename="logo_name_white.png" />
      {/* <p>{config.subHeading}</p> */}
    </div>
  );
}
