import React from 'react';
export default function ContactForm() {
  return (
    <form
      id="contact-us"
      name="contact"
      method="POST"
      data-netlify="true"
      netlify-honeypot="bot-field"
    >
      <input type="hidden" name="bot-field" />
      <div className="row">
        <div className="col-6 col-12-mobilep">
          <label htmlFor="name">
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Name"
              aria-label="Name"
            />
          </label>
        </div>
        <div className="col-6 col-12-mobilep">
          <label htmlFor="email">
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email address"
              aria-label="Email address"
            />
          </label>
        </div>
        <div className="col-12">
          <label htmlFor="message">
            <textarea
              id="message"
              name="message"
              placeholder="Message"
              rows="6"
              aria-label="Message"
            ></textarea>
          </label>
        </div>
        <div className="col-12">
          <ul className="actions special">
            <li>
              <input type="submit" value="Send Message" />
            </li>
          </ul>
        </div>
      </div>
    </form>
  );
}
