module.exports = {
  siteTitle: 'Blake Wolf | Official Website', // <title>
  manifestName: 'Blake Wolf',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/images/website-icon.png',
  // pathPrefix: `/gatsby-starter-directive/`,
  heading: 'Blake Wolf',
  subHeading: 'Music for videos, advertising and multimedia.',
  // social
  socialLinks: [
    // {
    //   icon: 'fab fa-twitter',
    //   name: 'Twitter',
    //   url: 'https://twitter.com/Blake__Wolf',
    // },
    {
      icon: 'fa-facebook-f',
      name: 'Facebook',
      url: 'https://www.facebook.com/Blake.Wolf.Music',
    },
    {
      icon: 'fab fa-spotify',
      name: 'Spotify',
      url: 'https://open.spotify.com/artist/2HhjUIRopP920SMpAonBZz',
    },
    {
      icon: 'custom-icon deezer-icon',
      name: 'Deezer',
      url: 'https://www.deezer.com/us/artist/86548142',
    },
    {
      // icon: 'custom-icon youtube-icon',
      icon: 'fa-youtube-play',
      name: 'Youtube',
      url: 'https://www.youtube.com/c/BlakeWolf',
    },
    {
      // icon: 'custom-icon bandcamp-icon',
      icon: 'fab fa-bandcamp',
      name: 'Bandcamp',
      url: 'https://blakewolf.bandcamp.com/',
    },
    {
      // icon: 'custom-icon googleplay-icon',
      icon: 'fa-google',
      name: 'Google Play',
      url:
        'https://play.google.com/store/music/artist?id=Aw6ucmkt3wtfhxquq5ngjirg7ga',
    },
    {
      icon: 'fab fa-soundcloud',
      name: 'Soundcloud',
      url: 'https://soundcloud.com/blake_wolf',
    },
    {
      // icon: 'custom-icon applemusic-icon',
      icon: 'fa-apple',
      name: 'Apple Music',
      url: 'https://music.apple.com/us/artist/blake-wolf/1498119083',
    },
    // {
    //   icon: 'custom-icon tidal-icon',
    //   name: 'Tidal',
    //   url: 'https://store.tidal.com/fr/artist/18446372',
    // },
    // {
    //   icon: 'custom-icon jamendo-icon',
    //   name: 'Jamendo',
    //   url: 'https://www.jamendo.com/artist/460908/joystock',
    // },
    // {
    //   icon: 'custom-icon napster-icon',
    //   name: 'Napster',
    //   url:
    //     'https://us.napster.com/artist/blake-wolf',
    // },
    // {
    //   icon: 'custom-icon iheartradio-icon',
    //   name: 'iHeartRadio',
    //   url: 'https://www.iheart.com/artist/blake-wolf-34081497/',
    // },
    {
      icon: 'fab fa-amazon',
      name: 'Amazon Music',
      url: 'https://music.amazon.com.au/artists/B0851SK29Y',
    },

    // {
    //   icon: 'fa-envelope-o',
    //   name: 'Email',
    //   url: 'mailto:karaza.franz@gmail.com',
    // },
    {
      icon: 'fa-linkedin-square',
      name: 'Linkedin',
      url: 'https://www.linkedin.com/company/blake-wolf',
    },
  ],
};
