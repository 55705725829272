import React from 'react';
import Modal from 'react-modal';
import ImageGatsby from './Image';

const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.58)',
  },
  content: {
    position: 'relative',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    maxWidth: '1000px',
    // height: '800px',
    height: '600px',
    padding: 0,
    border: 0,
    transform: 'translate(-50%, -50%)',
  },
};

Modal.setAppElement(`#___gatsby`);

const Album = ({ title, desc, tag1, tag2, tag3 }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className="album">
      <div className="cover">
        <ImageGatsby
          alt={`Album "${title}" by Blake Wolf`}
          filename={`covers/${title.replace(/\s+/g, '_').toLowerCase()}.jpg`}
        />
      </div>
      <div className="content">
        <h3>{title}</h3>
        {tag1 ? <span className="pill">{tag1}</span> : ''}
        {tag2 ? <span className="pill">{tag2}</span> : ''}
        {tag3 ? <span className="pill">{tag3}</span> : ''}
        <p>{desc}</p>
        <ul className="actions special">
          <li>
            <button
              className="button"
              onClick={openModal}
              aria-label={`Listen to the album "${title}" by Blake Wolf on different platforms `}
              title={`Listen to the album "${title}" by Blake Wolf on different platforms `}
            >
              Listen
            </button>
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              contentLabel="Example Modal"
              style={modalStyles}
            >
              <button
                onClick={closeModal}
                className="close-button"
                data-close
                aria-label="Close modal"
                type="button"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <iframe
                src={`https://blake-wolf.fanlink.to/${title
                  .replace(/\s+/g, '-')
                  .toLowerCase()}?embed=true`}
                allowFullScreen
                frameBorder="0"
                width="100%"
                // height="800"
                height="600"
                title={title}
              ></iframe>
            </Modal>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Album;
