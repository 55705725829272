import React from 'react';

import Layout from '../components/Layout';

import Header from '../components/Header';
import Footer from '../components/Footer';

import Album from '../components/Album';
import Image from '../components/Image';

import config from '../../config';

const IndexPage = () => (
  <Layout>
    <Header />

    <div id="main">
      <header className="major container medium">
        <Image alt="Blake Wolf logo" filename="logo_rounded.png" />
        <h1>Blake Wolf</h1>
        {/* <h2>
          Blake Wolf is available on Youtube, Spotify, Deezer, Soundcloud, Tidal, and 150
          other music stores.
        </h2> */}
        <h2>
          Blake Wolf is available on{' '}
          <a
            className="text-decoration-none"
            target="_blank"
            rel="noopener noreferrer"
            title="Blake Wolf on Youtube"
            href="https://www.youtube.com/c/BlakeWolf"
          >
            Youtube
          </a>
          ,{' '}
          <a
            className="text-decoration-none"
            target="_blank"
            rel="noopener noreferrer"
            title="Blake Wolf on Spotify"
            href="https://open.spotify.com/artist/2HhjUIRopP920SMpAonBZz"
          >
            Spotify
          </a>
          ,{' '}
          <a
            className="text-decoration-none"
            target="_blank"
            rel="noopener noreferrer"
            title="Blake Wolf on Deezer"
            href="https://www.deezer.com/us/artist/86548142"
          >
            Deezer
          </a>
          ,{' '}
          <a
            className="text-decoration-none"
            target="_blank"
            rel="noopener noreferrer"
            title="Blake Wolf on Soundcloud"
            href="https://soundcloud.com/blake_wolf"
          >
            Soundcloud
          </a>
          , Tidal, and 150 other music stores.
        </h2>
        <ul className="icons dark">
          {config.socialLinks.map(social => {
            const { icon, name, url } = social;
            return (
              <li key={url}>
                <a
                  href={url}
                  className={`icon ${icon}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  title={`Blake Wolf on ${name}`}
                >
                  <span className="label">{name}</span>
                </a>
              </li>
            );
          })}
        </ul>
      </header>

      <div className="box alt container">
        <Album
          title="Level Up"
          desc="Level Up is a unique and powerful blend between orchestral and electronic music featuring horns, a violin ensemble, and piano."
          tag1="electronic"
          tag2="orchestral"
        />
        <Album
          title="Grinding"
          desc="Grinding is an extremely powerful electronic beat featuring punchy drums and intense basses."
          tag1="crushing"
          tag2="massive"
        />
        <Album
          title="Digitalized"
          desc="Digitalized is a flamboyant electronic track featuring glitchy sounds, a massive choir and deep synths."
          tag1="electronic"
          tag2="choir"
        />
        <Album
          title="Next Generation"
          desc="With its playfully disjointed percussion and high anticipation and excitement, Next Generation is a characterful track with sophisticated production."
          tag1="trap"
          tag2="percussion"
        />
        <Album
          title="Just Lovin"
          desc="Sparkling and full of life, Just Lovin is a fresh electronic track featuring modern synths and catchy vocal chops."
          tag1="funky"
          tag2="bouncy"
        />
        <Album
          title="Popsicle"
          desc="Popsicle is a vibrant electro-pop summer anthem filled with enthusiasm. Perfect for high energy videos."
          tag1="pop"
          tag2="catchy"
        />
        <Album
          title="Young At Heart"
          desc="Fast paced, upbeat and super lively, Young At Heart is a high energy pop-rock track featuring electric guitar, claps and background vocals."
          tag1="poprock"
          tag2="happy"
        />
        <Album
          title="The Information Age"
          desc="The Information Age is a futuristic and inspiring electronic track featuring a deep analog bassline, glitchy sounds, and vocal chops."
          tag1="electronic"
          tag2="futuristic"
        />

        <Album
          title="Write Your Story"
          desc="Write Your Story is an inspiration acoustic guitar track featuring strings and glockenspiel."
          tag1="acoustic"
          tag2="storytelling"
        />
        <Album
          title="Prism"
          desc="Prism is a modern corporate track featuring complex and coloured electronic textures. Sounds like technology."
          tag1="corporate"
          tag2="modern"
        />
        {/* <Album
          title="Divinity"
          desc="Divinity is an extremely powerful blend of orchestral and electronic music featuring a choir, a violin ensemble, and piano."
          tag1="orchestral"
          tag2="massive"
        /> */}
        <Album
          title="Persian Potion"
          desc="Persian Potion is a highly unique and creative track that features elements from electronic and traditional arabic music."
          tag1="exotic"
          tag2="electronic"
        />
        <Album
          title="Neon Lights"
          desc="Neon Lights is a retro-futuristic track featuring deep analog synths. A superb cyberpunk audio landscape."
          tag1="analog"
          tag2="90s"
        />
        <Album
          title="Aura"
          desc="Upbeat and colorful, Aura features a punchy hip hop beat and catchy future bass synths."
          tag1="future bass"
          tag2="beat"
        />
        <Album
          title="Emergence"
          desc="Emergence is a modern and impactful dubstep track with a colorful and inspiring mood."
          tag1="dubstep"
          tag2="intense"
        />

        <Album
          title="Future Is Now"
          desc="
          Explosive synths, futuristic effects and inspiring vibes. Future Is Now is a concentrate of pure future bass energy."
          tag1="future bass"
          tag2="electronic"
          // iframe="https://blake-wolf.fanlink.to/future-is-now?embed=true"
        />
        <Album
          title="Get Up"
          desc="Groovy bassline, sexy guitar riffs, soulful beat and classic brass, Get Up has all the key ingredients to make a booty shake."
          tag1="funky"
          tag2="groovy"
        />
        <Album
          title="Ectoplasmic"
          desc="Ectoplasmic is a super catchy funk track that features upbeat drums, a groovy bassline, and powerful synths."
          tag1="funk"
          tag2="bouncy"
        />
        <Album
          title="Coffee Break"
          desc="Lay down and have a sip of relaxation. Coffee Break is a soft chillout hip hop beat with hints lofi and jazz."
          tag1="lofi"
          tag2="hip hop"
        />
        <Album
          title="Sacred Spirits"
          desc="Sacred Spirits is an absolutely massive abstract track that takes features dubstep-style basses and extra powerful drums."
          tag2="dubstep"
        />
        <Album
          title="Redesigned"
          desc="Stylish and energetic future bass track featuring massive synth chords, a bright melody and modern drums."
          tag2="electronic"
        />
        <Album
          title="Show Time"
          desc="Show Time is a blasting jazzy hip hop track that features piano, saxophones, a sweet bassline and punchy drums."
          tag1="jazzy"
          tag2="hip hop"
        />
        <Album
          title="Ethereum"
          desc="Glitchy electronic tones are combined with a deep bass to form this exquisite and futuristic sounding soundtrack."
          tag1="electronic"
          tag2="futuristic"
        />
        <Album
          title="Hawaii"
          desc="Hawaii is a blend between corporate music and upbeat summer-sounding pop, featuring a catchy melody."
          tag1="electropop"
        />
        <Album
          title="Opulence"
          desc="Lay back in a hamac, grab an exotic drink, and relax. Opulence is a soothing and modern lounge track delicacy."
          tag1="lounge"
          tag2="chillout"
        />
        <Album
          title="Funky Beach"
          desc="Between Hip Hop, Funk and Electronic, Funky Beach has what it takes to hit the musical sweet spot."
          tag1="electronic"
          tag2="pop"
        />
      </div>

      <footer className="major container medium">
        <h3>Need to use a track in your video?</h3>
        <p>
          All of Blake Wolf’s music is royalty-free and is available for use in
          multimedia projects. To use the music in a project, you will need a
          license (written authorization).
        </p>
        <ul className="actions special">
          <li>
            <a
              className="button"
              href="#contact-us"
              title="Contact Us"
              aria-label="Contact Us"
            >
              Contact Us
            </a>
          </li>
        </ul>
      </footer>
    </div>
    <Footer />
  </Layout>
);

export default IndexPage;
